import {
  Component,
  Show,
  ComponentProps,
  onMount,
  JSX,
  createSignal,
} from "solid-js";
import { Dynamic } from "solid-js/web";
import { TextField as KTextField } from "@kobalte/core";
import { Icon } from "solid-heroicons";
import { eyeSlash } from "solid-heroicons/solid";

export type TextFieldRootProps = ComponentProps<typeof KTextField.Root>;
export type TextFieldInputProps = ComponentProps<typeof KTextField.Input>;
export type TextAreaProps = ComponentProps<typeof KTextField.TextArea>;
export type TextFieldProps = Pick<
  TextFieldRootProps,
  | "validationState"
  | "name"
  | "defaultValue"
  | "disabled"
  | "value"
  | "onChange"
> &
  Pick<TextFieldInputProps, "onInput" | "autocomplete" | "maxLength"> & {
    label?: string;
    type?: string;
    error?: string | string[] | null;
    class?: string;
    labelClass?: string;
    description?: string;
    placeholder?: string;
    rootClass?: string;
    focusOnLoad?: boolean;
    icon?: { path: JSX.Element };
    required?: boolean;
    id?: string;
  } & Pick<TextAreaProps, "autoResize">;

export const TextFieldInput: Component<TextFieldProps> = (props) => {
  const [passwordVis, setPasswordVis] = createSignal(false);
  let inputRef: HTMLElement;

  onMount(() => {
    if (props.focusOnLoad) inputRef.focus();
  });

  return (
    <KTextField.Root
      name={props.name}
      disabled={props.disabled}
      validationState={props.validationState}
      class={props.rootClass}
      onChange={props.onChange}
      value={props.value}
      defaultValue={props.defaultValue}
    >
      <Show when={props.label}>
        <KTextField.Label
          for={props.name}
          class="block ml-1 mb-2 text-sm font-bold text-roma-dark-grey"
          classList={{ [`${props.labelClass}`]: !!props.labelClass }}
        >
          {props.label}
        </KTextField.Label>
      </Show>
      <div class="relative">
        <Dynamic
          autocomplete={props.autocomplete}
          component={
            props.type === "textarea" ? KTextField.TextArea : KTextField.Input
          }
          autoResize={props.type === "textarea" ? props.autoResize : undefined}
          maxLength={props.maxLength}
          ref={(el: HTMLElement) => (inputRef = el)}
          id={props.id}
          type={
            props.type !== "password"
              ? props.type
              : passwordVis() === false
              ? "password"
              : "text"
          }
          name={props.name}
          onInput={props.onInput}
          class="bg-white text-sm block px-3 py-2 border w-full bg-transparent rounded-md appearance-none focus-roma outline-none focus:outline-none peer transition-colors duration-200 placeholder:text-gray-400 placeholder:font-medium"
          placeholder={props.placeholder || " "}
          classList={{
            "border !border-gray-300 text-roma-dark-grey focus-roma hover:border-neutral-400":
              props.validationState !== "invalid",
            "!border-red-300 border focus:ring-error focus:border-error":
              props.validationState === "invalid",
            [`${props.class}`]: !!props.class,
            "pl-10": !!props.icon || props.type === "password",
          }}
        />
        <Show when={props.icon || props.type === "password"}>
          <div class="absolute inset-y-0 flex items-center mx-3">
            <Show
              when={props.type === "password"}
              fallback={
                <Icon
                  path={props.icon as { path: JSX.Element }}
                  class="w-5 text-gray-400"
                />
              }
            >
              <button
                tabIndex={-1}
                type="button"
                onClick={() => setPasswordVis(!passwordVis())}
              >
                <Icon
                  path={eyeSlash}
                  class={`w-5 ${
                    passwordVis() ? "text-gray-600" : "text-gray-400"
                  }`}
                />
              </button>
            </Show>
          </div>
        </Show>
      </div>
      <Show when={props.description}>
        <KTextField.Description class="mt-1">
          {props.description}
        </KTextField.Description>
      </Show>
      <KTextField.ErrorMessage class="flex items-center gap-1 text-red-600 text-xs mt-2">
        <div>
          {Array.isArray(props.error) ? props.error.join(", ") : props.error}
        </div>
      </KTextField.ErrorMessage>
    </KTextField.Root>
  );
};
