import {
  Component,
  For,
  Show,
  mergeProps,
  onMount,
  JSX,
  createSignal,
} from "solid-js";
import { Dynamic } from "solid-js/web";
import { Icon } from "solid-heroicons";
import { exclamationTriangle } from "solid-heroicons/outline";

export const InputCountryCode: Component<{
  countryCodes: string[];
}> = (props) => {
  return (
    <select
      id="country"
      name="country"
      autocomplete="country"
      class="h-full rounded-md border-transparent bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
    >
      <For each={props.countryCodes}>{(code) => <option>{code}</option>}</For>
    </select>
  );
};

export const Input: Component<{
  name: string;
  onInput?: (value: any) => void;
  onKeyPress?: (value: any) => void;
  onFocus?: (value: any) => void;
  onLoad?: (value: any) => void;
  onBlur?: (value: any) => void;
  onChange?: (value: any) => void;
  onFocusOut?: (value: any) => void;
  id?: string;
  label?: string;
  class?: string;
  tabIndex?: string;
  focusOnLoad?: boolean;
  disabled?: boolean;
  autocomplete?: string;
  outerClass?: string;
  labelClass?: string;
  shadowClass?: string;
  type?:
    | "text"
    | "email"
    | "phone"
    | "textarea"
    | "password"
    | "number"
    | "date";
  required?: boolean;
  placeholder?: string;
  value?: string | number;
  pre?: JSX.Element;
  icon?: IconPath;
  error?: string | boolean;
  min?: number | string;
  max?: number | string;
  maxLength?: number;
  pattern?: string;
  step?: number;
}> = (inProps) => {
  const props = mergeProps(
    {
      type: "text",
      required: false,
      value: "",
      placeholder: "",
    },
    inProps
  );
  let ref: HTMLElement;
  onMount(() => {
    if (props.focusOnLoad) ref.focus();
    if (props.onLoad) props.onLoad(ref);
  });

  const [passwordVis, setPasswordVis] = createSignal(false);
  return (
    <>
      <div class={props.outerClass}>
        <Show when={props.label}>
          <label
            for={props.id || props.name}
            class="block ml-1 mb-2 text-sm font-bold"
            classList={{ [`${props.labelClass}`]: !!props.labelClass }}
          >
            {props.label}
          </label>
        </Show>
        <div
          class="relative rounded-md shadow-sm"
          classList={{ [`${props.shadowClass}`]: !!props.shadowClass }}
        >
          <Show when={props.pre || props.icon}>
            <button
              aria-label={
                props.type === "password" ? "Show Password" : props.name
              }
              type="button"
              class="absolute inset-y-0 flex items-center"
              classList={{
                "cursor-pointer right-0": props.type === "password",
              }}
              onClick={
                props.type === "password"
                  ? () => {
                      setPasswordVis(!passwordVis());
                    }
                  : undefined
              }
            >
              <Show when={props.icon} fallback={props.pre} keyed>
                {(icon) => (
                  <Icon
                    class="mx-3 w-5 text-gray-400"
                    path={icon}
                    classList={{
                      "text-gray-800":
                        props.type === "password" && passwordVis(),
                    }}
                  />
                )}
              </Show>
            </button>
          </Show>
          <Dynamic
            id={props.id || props.name}
            ref={(el: HTMLElement) => (ref = el)}
            tabIndex={props.tabIndex}
            component={props.type === "textarea" ? "textarea" : "input"}
            name={props.name}
            type={
              props.type !== "password"
                ? props.type
                : passwordVis() === false
                ? "password"
                : "text"
            }
            step={props.step}
            disabled={props.disabled}
            onInput={props.onInput}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onKeyPress={props.onKeyPress}
            onLoad={props.onLoad}
            onChange={props.onChange}
            autocomplete={props.autocomplete}
            class="block w-full border px-3 py-2 rounded-md placeholder:text-sm"
            classList={{
              "border-gray-300 focus-roma": !props.error,
              "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 ":
                !!props.error,
              "pl-20": !!props.pre,
              "pl-10": !!props.icon,
              "pl-3": props.type === "password",
              [`${props.class}`]: !!props.class,
            }}
            placeholder={props.placeholder}
            value={props.value}
            required={props.required}
            aria-label={props.name || "Clickable"}
            aria-invalid={!props.error}
            aria-describedby={`${props.id}-error`}
            onFocusOut={props.onFocusOut ? props.onFocusOut : undefined}
            min={props.min}
            max={props.max}
            maxLength={props.maxLength}
            pattern={props.pattern}
          />
          <Show when={props.error}>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <Icon path={exclamationTriangle} class="w-5 text-red-500" />
            </div>
          </Show>
        </div>
        <Show when={props.error}>
          <p class=" text-xs mt-2 mb-4 text-red-600" id={`${props.id}-error`}>
            {props.error}
          </p>
        </Show>
      </div>
    </>
  );
};
